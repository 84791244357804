import AuthService from '@services/auth/api'
import axios from 'axios'

const IS_REDIRECT_AFTER_REGISTRATION = true

export default {
  data() {
    return {
      registrationPhone: '',
      registrationFio: '',
      registrationPassword: '',
      registrationRepeatedPassword: '',
      confirmCode: '',
      registrationEmail: '',
      confirmCodeAskedMessage: '',
      confirmPhoneMessage: '',
      registrationResponseMessage: '',
      agreementCheckbox: false,
      isConfirmCodeAsked: false,
      isPhoneConfirmed: false,
      isUserRegistered: false,
      isLoading: false,
      isConfirmCodeLoading: false,
      isConfirmCodeNumberLoading: false,
    }
  },
  computed: {
    rawRegistrationPhone() {
      return `+7${this.registrationPhone}`
    },
    isCustomer() {
      return this.chosenRegistrationButton.id === 2 || this.chosenRegistrationButton.id === 4
    },
    isLegalEntityCusomer() {
      return this.chosenRegistrationButton.id === 4
    },
  },
  methods: {
    async sendRegisterRequest() {
      this.$v.$touch()

      if (this.$v.$invalid) return
      this.isLoading = true

      const token = await this.tokenGenerator()
      const formData = new FormData()

      formData.append('token', token)
      formData.append('User[fio]', this.registrationFio)
      formData.append('User[pass]', this.registrationPassword)
      formData.append('User[repeat]', this.registrationPassword)
      formData.append('User[type]', this.isCustomer ? 2 : this.chosenRegistrationButton.id)
      formData.append('User[phone]', this.rawRegistrationPhone)
      formData.append('User[code]', this.confirmCode)
      formData.append('User[regchecklic]', this.agreementCheckbox)
      formData.append('User[city]', window.cityId)
      formData.append('promo_code', this.appliedPromocode)

      // 4 - Заказчик юр. лицо
      if (this.isLegalEntityCusomer) {
        formData.append('User[email]', this.registrationEmail)
        formData.append('User[legal_entity_type]', 1)

        const dateLegalEntity = new Date().toLocaleString('ru').replace(/\./g, '-').replace(',', '')

        formData.append('User[legal_entity_type_change]', dateLegalEntity)
      }

      try {
        await AuthService.registerUser(formData)

        if (!IS_REDIRECT_AFTER_REGISTRATION) return

        setTimeout(() => {
          document.location = '/'
        }, 1000)
      } catch (e) {
        console.error(e)
        this.$toast.error(e.message)
      } finally {
        this.isLoading = false
      }
    },
    fieldsSomeError(fields) {
      return fields.some(field => this.$v[field].$invalid)
    },
    async sendConfirmNumber() {
      this.$v.$touch()

      this.isConfirmCodeLoading = true

      const token = await this.tokenGenerator()
      const formData = new FormData()

      formData.append('token', token)
      formData.append('phone', this.rawRegistrationPhone)

      try {
        const { data: response } = await axios.post(
          `/eApis/frontend/auth/sendConfirmNumber`,
          formData,
          {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          }
        )

        this.setCountDown()
        this.isConfirmCodeAsked = response.success
        this.confirmCodeAskedMessage = response.message
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        this.isConfirmCodeLoading = false
      }
    },
    async confirmNumber() {
      this.isConfirmCodeNumberLoading = true

      const formData = new FormData()

      formData.append('phone', this.rawRegistrationPhone)
      formData.append('code', this.confirmCode)

      try {
        const { data: response } = await axios.post(
          `/eApis/frontend/auth/confirmNumber`,
          formData,
          {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          }
        )

        this.isPhoneConfirmed = response.success
        this.confirmPhoneMessage = response.message
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        this.isConfirmCodeNumberLoading = false
      }
    },
    async callConfirmActions() {
      this.isConfirmCodeLoading = true
      await this.setTempUser()
      await this.sendConfirmNumber()
    },
    checkConfirmCode() {
      if (this.$v.confirmCode.$invalid) return

      this.confirmNumber()
    },
  },
}
