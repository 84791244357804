import { AJAX_REQUEST_HEADERS } from '@constants'
import {
  CHECK_USER_PROMOCODE_API,
  GET_TEMP_USER_API,
  RECOVERY_PASSWORD_API,
  REGISTER_USER_API,
  SEND_RECOVERY_CODE_API,
  SET_TEMP_USER_API,
} from '@constants/apiEndpoints'
import axios from 'axios'

class AuthService {
  /**
   * @param {Object} data awaiting form object
   * @param {string} data.phone - phone
   * @param {string} data.fio - fio
   * @param {string} data.pass - password
   */
  static async setTempUser(data) {
    const response = await axios.post(SET_TEMP_USER_API, data, AJAX_REQUEST_HEADERS)

    if (!response.data.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response.data
  }

  /**
   * @param {Object} data awaiting form object
   * @param {string} data.name - user name
   * @param {string} data.phone phone number
   */
  static async getTempUser() {
    const response = await axios.get(GET_TEMP_USER_API, { AJAX_REQUEST_HEADERS })

    if (!response.data.success)
      throw new Error(response.data.message || 'Ошибка при получении данных формы регистрации')
    return response.data
  }

  /**
   * @param {Object} data awaiting form object
   * @param {string} data.name - user name
   * @param {string} data.phone phone number
   */
  static async sendRecoveryCode(data) {
    const response = await axios.post(SEND_RECOVERY_CODE_API, data, AJAX_REQUEST_HEADERS)

    if (!response.data.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response.data
  }

  /**
   * @param {Object} data awaiting form object
   * @param {string} data.username - user name
   * @param {string} data.code recovery cide
   * @param {string} data.password password
   * @param {string} data.passwordRepeat password
   */
  static async recoveryPassword(data) {
    const response = await axios.post(RECOVERY_PASSWORD_API, data, AJAX_REQUEST_HEADERS)

    if (!response.data.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response.data
  }

  /**
   * @param {Object} data awaiting form object
   * @param {string} data.promo_code - promo
   */
  static async checkPromocode(data) {
    const response = await axios.post(CHECK_USER_PROMOCODE_API, data, AJAX_REQUEST_HEADERS)

    if (!response.data.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response.data
  }

  static async registerUser(data) {
    const response = await axios.post(REGISTER_USER_API, data, AJAX_REQUEST_HEADERS)

    if (!response.data?.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response
  }
}

export default AuthService
