import Vue from 'vue'
import Vuex from 'vuex'
import VueMask from 'v-mask'
import VueToast from 'vue-toast-notification'
import Vuelidate from 'vuelidate'
import 'vue-toast-notification/dist/theme-default.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import checkIosVersion from '@utils/checkIosVer'
import store from './store'
import ModalForms from './App.vue'
import '@services/axiosDefaults'

Vue.use(Vuex)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(VueToast, {
  position: 'top',
})
library.add(faSpinner)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({ store, render: h => h(ModalForms) }).$mount('#modal-forms')

// Модальные окна

// Добавляем слушатели на кнопки, которые находятся не под управлением vue, чтобы открывать модальные окна
const modalTriggerHandler = e => {
  switch (e.target.dataset.modal || e.target.parentNode.dataset.modal) {
    case 'reg':
      store.commit('updateModalData', { key: 'isRegModalOpened', value: true })
      break
    case 'auth':
      store.commit('updateModalData', { key: 'isAuthModalOpened', value: true })
      break
    case 'feedback':
      store.commit('updateModalData', { key: 'isFeedbackModalOpened', value: true })
      break
    case 'review':
      store.commit('updateModalData', { key: 'isReviewModalOpened', value: true })
      break
    default:
  }
}

document.querySelectorAll('.btn-modal').forEach(el => {
  el.addEventListener('click', modalTriggerHandler, { passive: true })
})

// События закрытия модальных окон при клике на esc
const modalCloseTriggerHandler = e => {
  setTimeout(() => {
    if (e.key === 'Escape') {
      if (store.state.isRecoveryModalOpened)
        store.commit('updateModalData', { key: 'isRecoveryModalOpened', value: false })
      if (store.state.isRegModalOpened)
        store.commit('updateModalData', { key: 'isRegModalOpened', value: false })
      if (store.state.isAuthModalOpened)
        store.commit('updateModalData', { key: 'isAuthModalOpened', value: false })
      if (store.state.isFeedbackModalOpened)
        store.commit('updateModalData', { key: 'isFeedbackModalOpened', value: false })
      if (store.state.isReviewModalOpened)
        store.commit('updateModalData', { key: 'isReviewModalOpened', value: false })
    }
  }, 0)
}

document.addEventListener('keyup', modalCloseTriggerHandler, { passive: true })

// Метрика
const sendMetricks = e => {
  if (typeof ym === 'undefined') return

  switch (e.target.dataset.alias) {
    case 'rabota_kurierom_na_lichnom_avto':
      // eslint-disable-next-line no-undef
      ym(41864514, 'reachGoal', 'carcourier_registration')
      break
    case 'rabota_peshim_kurierom':
      // eslint-disable-next-line no-undef
      ym(41864514, 'reachGoal', 'courier_registration')
      break
    default:
  }
}

// Если вызываем регистрацию с кнопки "Стать курьером" - сразу выставляем тип "Курьер"
const courierModalBtns = document.querySelectorAll('.courier.btn-modal')

courierModalBtns.forEach(courierModalBtn => {
  courierModalBtn.addEventListener(
    'click',
    e => {
      store.commit('setRegistrationButton', store.state.registrationButtons[2])
      sendMetricks(e)
    },
    { passive: true }
  )
})

// Если вызываем регистрацию с шапки - сразу выставляем тип "Заказчик"
const clientModalBtns = document.querySelectorAll('.client.btn-modal')

clientModalBtns.forEach(clientModalBtn => {
  clientModalBtn.addEventListener(
    'click',
    () => {
      store.commit('setRegistrationButton', store.state.registrationButtons[0])
    },
    { passive: true }
  )
})

// Убираем скролл на экранах, когда открыты модальные окна
window.MutationObserver = window.MutationObserver || window.WebKitMutationObserver

const body = document.querySelector('body')

const observer = new MutationObserver(mutations => {
  mutations.forEach(mutation => {
    mutation.addedNodes.forEach(addedNode => {
      if (addedNode.tagName) {
        if (addedNode.classList.contains('modals')) {
          body.classList.add('modal-opened')

          // кастомные скроллбары не поддерживаются с 14 ios - проверяем
          if (checkIosVersion() < 14) {
            body.classList.add('has-custom-scrollbar')
          }
        }
      }
    })

    mutation.removedNodes.forEach(removedNode => {
      if (removedNode.tagName) {
        if (
          removedNode.classList.contains('modals') &&
          !removedNode.classList.contains('fade-move')
        ) {
          body.classList.remove('modal-opened')
          body.classList.remove('has-custom-scrollbar')
        }
      }
    })
  })
})

observer.observe(document, {
  childList: true,
  subtree: true,
})
