<template>
  <ModalUi
    @closeModal="updateModalData({ key: 'isAuthModalOpened', value: false })"
    id="auth"
    class="modal"
  >
    <div class="modal-block">
      <i
        class="close"
        @click="updateModalData({ key: 'isAuthModalOpened', value: false })"
        @keypress="updateModalData({ key: 'isAuthModalOpened', value: false })"
      >
        <img src="/images/newMain/remove-blue.svg" alt="remove" />
      </i>
      <h3 class="title">Вход в личный кабинет</h3>
      <form>
        <div class="validation-wrapper">
          <input
            type="text"
            name="phone"
            class="modal-input"
            placeholder="Номер телефона или e-mail"
            v-model="authLogin"
          />
        </div>
        <div class="validation-wrapper">
          <input
            type="password"
            name="password"
            class="modal-input"
            placeholder="Введите пароль"
            v-model="authPassword"
          />
        </div>
        <div class="modal-buttons extra-margin">
          <button @click.prevent="sendAuthRequest" class="send btn-single" type="button">
            <span v-if="!isLoading">Войти</span>
            <Spinner v-else />
          </button>
        </div>
        <a
          href="javascript:void(0);"
          class="forgot-password-btn"
          @click.prevent="updateModalData({ key: 'isRecoveryModalOpened', value: true })"
        >
          Забыли пароль?
        </a>
      </form>
    </div>
  </ModalUi>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ModalUi from '@features/UI/ModalUi.vue'
import axios from 'axios'
import Spinner from '@features/UI/FontAwesomeSpinner.vue'

export default {
  name: 'authorization',
  components: { ModalUi, Spinner },
  data() {
    return {
      authLogin: null,
      authPassword: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapState(['tokenGenerator', 'deeplink']),
  },
  methods: {
    ...mapMutations(['updateModalData']),
    async sendAuthRequest() {
      const fetchHandler = async () => {
        const token = await this.tokenGenerator()
        const formData = new FormData()

        formData.append('token', token)
        formData.append('LoginForm[username]', this.authLogin)
        formData.append('LoginForm[password]', this.authPassword)

        const response = await axios.post(`/eApis/frontend/auth/login`, formData, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })

        if (response.data.success) {
          if (this.deeplink) {
            setTimeout(() => {
              window.location.href = `${window.origin}/${this.deeplink}`
            })
          }

          document.location = '/'
        } else {
          this.$toast.error(response.data.message || 'Ошибка авторизации', { position: 'top' })
        }
      }

      this.isLoading = true

      try {
        await fetchHandler()
      } catch (e) {
        console.error(e.message)
        this.$toast.error(e.message)
      }

      this.isLoading = false
    },
  },
}
</script>
