import { load } from 'recaptcha-v3'

const tokenGenerator = () =>
  new Promise((res, rej) => {
    load('6LfFiNcUAAAAAFqfIU95xzUGx-QNbON4k7EMykwz', { autoHideBadge: true }).then(recaptcha => {
      recaptcha
        .execute('login')
        .then(token => {
          res(token)
        })
        .catch(err => {
          rej(err)
        })
    })
  })

export default {
  data: () => ({
    tokenGenerator,
  }),
}
