<template>
  <div class="license-agreement">
    <div class="validation-wrapper">
      <div class="checkbox-wrapper">
        <input type="checkbox" id="agreement-checkbox" v-model="agreement" />
        <span>
          Я ознакомлен с <a href="/soglashenie" target="_blank">пользовательским соглашением</a>
        </span>
      </div>
      <transition name="fade" tag="div" mode="out-in">
        <div v-if="isAgreementError" class="invalid-feedback">
          Необходимо принять условия пользовательского соглашения
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isAgreementError: {
      type: Boolean,
      required: true,
    },
    agreementCheckbox: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    agreement: {
      get() {
        return this.agreementCheckbox
      },
      set(val) {
        this.$emit('update:agreementCheckbox', val)
      },
    },
  },
}
</script>
