<template>
  <div class="validation-wrapper">
    <input
      :disabled="isPhoneConfirmed"
      type="number"
      @input="$emit('input')"
      name="confirmCode"
      class="modal-input"
      placeholder="Код из смс"
      v-model="code"
    />
    <transition name="fade" tag="div" mode="out-in">
      <div>
        <Spinner style="color: black" v-if="isConfirmCodeNumberLoading" />
        <div
          v-if="confirmPhoneMessage?.length"
          :class="isPhoneConfirmed ? 'success-feedback' : 'invalid-feedback'"
        >
          {{ confirmPhoneMessage }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Spinner from '@features/UI/FontAwesomeSpinner.vue'

export default {
  components: { Spinner },
  props: {
    confirmCode: {
      type: String,
      required: true,
    },
    confirmPhoneMessage: {
      type: String,
      required: true,
    },
    isPhoneConfirmed: {
      type: Boolean,
      required: true,
    },
    isConfirmCodeNumberLoading: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    code: {
      get() {
        return this.confirmCode
      },
      set(val) {
        this.$emit('update:confirmCode', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.validation-wrapper {
  position: relative;

  :deep(.spinner) {
    position: absolute;
    top: 16px;
    right: 12px;
  }
}
</style>
